<template>
  <a-modal
      v-model="showModel"
      :width='800'
      title="对版准确度统计"
      cancel-text="取消"
      @cancel="cancelPushModal"
  >
    <div class="push-modal">
      <div>
        <!-- 选择小币种 -->
        <a-select
            :dropdownMatchSelectWidth="false"
            @change="changeCoinItem"
            :filter-option="untils.filterOption"
            allowClear
            showSearch
            size="small"
            style="margin: 5px; width: 220px"
            v-model="coinId"
            placeholder="不选择币种，默认导出全部"
        >
          <a-select-option
              v-for="item of coinList"
              :key="item.coinId"
              :value="item.coinId"
          >{{ item.coinName }}</a-select-option>
        </a-select>
        <a-button
            icon="download"
            class="btn"
            type="primary"
            size="small"
            :loading="loadingBtn"
            @click="handleDownloadData"
        >{{ btnText }}</a-button>
      </div>
    </div>
    <div slot="footer">
      <a-button @click="cancelPushModal">关闭</a-button>
    </div>
  </a-modal>
</template>

<script>
import untils from "@/untils";
export default {
  data() {
    return {
      untils,
      btnText: '统计数据导出',
      loadingBtn: false,
      showModel: false,
      coinId: undefined,
      coinName: '全部',
      coinList: []
    };
  },
  methods: {
    /** 导出文件 */
    async handleDownloadData() {
      if (!this.coinId) return this.$message.info('请选择币种，现不支持全部导出')
      this.$loading.show()
      this.loadingBtn = true
      this.btnText = '导出中...'
      const res = await this.axios('/dq_admin/duibanCoinStatisticsDay/statisticsCoinByDayRatio', {
        params: {
          coinId: this.coinId
        }
      })
      this.$loading.hide()
      this.loadingBtn = false
      this.btnText = '统计数据导出'
      if (res.status != '200') return this.$message.warning('导出超时，请再次尝试')
      // 列标题
      const jsonData = res.data
      // let str = '<tr><td colspan="4"></td></tr>';
      let str = "";
      str +=
          "<tr style='text-align:center'>" +
            "<td>日期</td>" +
            "<td>币种ID</td>" +
            "<td>币种名称</td>" +
            "<td>当天对版有结果数量</td>" +
            "<td>当天top1=2数</td>" +
            "<td>当天top1=5数</td>" +
            "<td>当天多结果数</td>" +
            "<td>当天top1=2准确率</td>" +
            "<td>当天top1=5准确率</td>" +
            "<td>当天多结果比例</td>" +
          "</tr>";
      // 循环遍历，每行加入tr标签，每个单元格加td标签
      for (let i = 0; i < jsonData.length; i++) {
        str +=
            `<tr style='text-align:center'>` +
              `<td>${jsonData[i]["duibanDay"] + "\t"}</td>`+
              `<td>${jsonData[i]["coinKindId"] + "\t"}</td>`+
              `<td>${jsonData[i]["coinKindName"] + "\t"}</td>`+
              `<td>${jsonData[i]["recordDayNum"] + "\t"}</td>`+
              `<td>${jsonData[i]["top1eq2DayNum"] + "\t"}</td>`+
              `<td>${jsonData[i]["top1eq5DayNum"] + "\t"}</td>`+
              `<td>${jsonData[i]["topmanyDayNum"] + "\t"}</td>`+
              `<td>${jsonData[i]["top1eq2DayRate"] + "\t"}</td>`+
              `<td>${jsonData[i]["top1eq5DayRate"] + "\t"}</td>`+
              `<td>${jsonData[i]["topmanyDayRate"] + "\t"}</td>`+
            `</tr>`;
      }

      // Worksheet名
      const worksheet = "斗泉";
      const uri = "data:application/vnd.ms-excel;base64,";

      // 导出的表格模板数据
      const template = `<html xmlns:o="urn:schemas-microsoft-com:office:office"
        xmlns:x="urn:schemas-microsoft-com:office:excel"
        xmlns="http://www.w3.org/TR/REC-html40">
        <head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>
        <x:Name>${worksheet}</x:Name>
        <x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>
        </x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
        </head><body><table>${str}</table></body></html>`;
      // 导出模板
      const time = new Date()
      const dayStr = time.getFullYear() + '年' +  (time.getMonth() + 1) + '月' + time.getDate() + '日'
      const fileName = this.coinName + '-' + dayStr
      const href = uri + this.base64(template)
      let link = document.createElement('a');
      link.setAttribute('href', href);
      link.setAttribute('download', `${fileName}.xls`);
      link.click();
    },
    base64(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    /** 展示弹窗 */
    async show() {
      this.coinList = []
      this.coinId = undefined
      this.coinName = '全部'
      await this.getcCoinItemSearch()
      this.showModel = true
    },
    /** 改变币种 */
    changeCoinItem(e) {
      this.coinId = e
      try {
        this.coinName = this.coinList.find(el => el.coinId === e).coinName
      } catch (e) {console.log('')}
    },
    /** 可导出币种 */
    async getcCoinItemSearch() {
      const res = await this.axios("/dq_admin/duibanCoinStatisticsDay/supportCoins");
      this.coinList = res.data;
    },
    // 关闭
    cancelPushModal() {
      this.showModel = false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>