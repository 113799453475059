var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "list" },
        _vm._l(_vm.list, function(item, index) {
          return _c("div", { key: index, staticClass: "item" }, [
            _c("div", { staticClass: "content" }, [
              _c(
                "div",
                { staticClass: "image-box", attrs: { id: "img-back" + index } },
                [
                  _c("div", { ref: "imgBoxEl", refInFor: true }, [
                    _c("img", {
                      staticClass: "image",
                      attrs: { id: "img-box-el", src: _vm.showImage(item) },
                      on: {
                        click: function($event) {
                          return _vm.previewImg(index)
                        }
                      }
                    })
                  ]),
                  _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(item.id))])
                ]
              ),
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  " " +
                    _vm._s(item.coinKindItemName + "-" + item.coinVersionName) +
                    " "
                )
              ])
            ]),
            _c(
              "div",
              { staticClass: "footer" },
              [
                _c(
                  "a",
                  {
                    staticClass: "move-btn",
                    on: {
                      click: function($event) {
                        return _vm.showYuanImg($event, item)
                      }
                    }
                  },
                  [_vm._v("原图 ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "move-btn",
                    on: {
                      click: function($event) {
                        return _vm.handleShowSameVersionList($event, item)
                      }
                    }
                  },
                  [_vm._v("同版 ")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "move-btn",
                    on: {
                      click: function($event) {
                        return _vm.logClick(item)
                      }
                    }
                  },
                  [_vm._v("日志")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "move-btn",
                    on: {
                      click: function($event) {
                        return _vm.moveClick(item)
                      }
                    }
                  },
                  [_vm._v("挪动")]
                ),
                _c(
                  "a-dropdown",
                  {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "overlay",
                          fn: function() {
                            return [
                              _c(
                                "a-menu",
                                [
                                  _c("a-menu-item", [
                                    _vm.imageType == 0
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "move-btn",
                                            on: {
                                              click: function($event) {
                                                return _vm.badcaseCollect(
                                                  $event,
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.imageType == 0 &&
                                                  item.collect == 1
                                                  ? "已收集"
                                                  : "收集"
                                              ) + " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.imageType == 1
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "move-btn",
                                            on: {
                                              click: function($event) {
                                                return _vm.badcaseCollect(
                                                  $event,
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.imageType == 1 &&
                                                  item.backCollect == 1
                                                  ? "已收集"
                                                  : "收集"
                                              ) + " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定要推送此项么？",
                                            "ok-text": "确认",
                                            "cancel-text": "取消"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.pushClick(item.id)
                                            }
                                          }
                                        },
                                        [
                                          _c("a", { staticClass: "move-btn" }, [
                                            _vm._v("质检")
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c("a-menu-item", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "move-btn",
                                        on: {
                                          click: function($event) {
                                            return _vm.handleCutPic(
                                              $event,
                                              item
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("裁剪 ")]
                                    )
                                  ]),
                                  _c(
                                    "a-menu-item",
                                    [
                                      _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title: "确定要删除此项么？",
                                            "ok-text": "确认",
                                            "cancel-text": "取消"
                                          },
                                          on: {
                                            confirm: function($event) {
                                              return _vm.remove(item.id)
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "a",
                                            { staticClass: "color-red" },
                                            [_vm._v("删除")]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { color: "blue" },
                        attrs: { size: "small", type: "link" }
                      },
                      [_vm._v("更多")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        }),
        0
      ),
      _vm.list.length <= 0
        ? _c("a-empty", { staticClass: "null", attrs: { description: false } })
        : _vm._e(),
      _c("ConfirmMove", { ref: "move", on: { success: _vm.editSuccess } }),
      _c("ConfirmLog", { ref: "log" }),
      _c("ShowImg", { ref: "showImgEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }