<template>
  <div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="content">
          <div class="image-box" :id="'img-back' + index">
            <div ref="imgBoxEl">
              <img
                  id="img-box-el"
                  class="image"
                  :src="showImage(item)"
                  @click="previewImg(index)"
              />
            </div>
            <div class="tip">{{item.id}}</div>
          </div>

          <div class="name">
            {{`${item.coinKindItemName}-${item.coinVersionName}`}}
          </div>
          <!-- <div class="score">{{item.sourceInfo || ""}}</div> -->
        </div>
        <div class="footer">
          <a
             class="move-btn"
             @click="showYuanImg($event,item)"
          >原图
          </a>
          <a
              class="move-btn"
              @click="handleShowSameVersionList($event,item)"
          >同版
          </a>

          <a
            class="move-btn"
            @click="logClick(item)"
          >日志</a>
          <a
            class="move-btn"
            @click="moveClick(item)"
          >挪动</a>

          <a-dropdown>
            <a-button size="small" type="link" style="color: blue">更多</a-button>
            <template #overlay>
              <a-menu>
                <a-menu-item>
                  <a v-if="imageType == 0"
                     class="move-btn"
                     @click="badcaseCollect($event,item)"
                  >{{imageType == 0 && item.collect == 1 ? "已收集" : "收集"}}
                  </a>

                  <a v-if="imageType == 1"
                     class="move-btn"
                     @click="badcaseCollect($event,item)"
                  >{{imageType == 1 && item.backCollect == 1 ? "已收集" : "收集"}}
                  </a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                      title="确定要推送此项么？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="pushClick(item.id)"
                  >
                    <a class="move-btn">质检</a>
                  </a-popconfirm>
                </a-menu-item>
                <a-menu-item>
                  <a
                      class="move-btn"
                      @click="handleCutPic($event,item)"
                  >裁剪
                  </a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm
                      title="确定要删除此项么？"
                      ok-text="确认"
                      cancel-text="取消"
                      @confirm="remove(item.id)"
                  >
                    <a class="color-red">删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </div>
    <a-empty :description="false" class="null" v-if="list.length <= 0" />
    <ConfirmMove
      ref="move"
      @success="editSuccess"
    ></ConfirmMove>
    <ConfirmLog ref="log"></ConfirmLog>
    <ShowImg ref="showImgEl"/>
  </div>

</template>

<script>
import ConfirmMove from "@/views/cmsPage/versionManage/duibanconfirm/ConfirmMove.vue";
import ConfirmLog from "@/views/cmsPage/versionManage/duibanconfirm/ConfirmLog.vue";
import ShowImg from "@/views/cmsPage/versionManage/duibanconfirm/_components/ShowImg";
export default {

  props: {
    list: {
      default: [],
    },
    imageType: {
      default: 0, //0正面 1背面
    },
  },
  data() {
    return {
      frontOrBackList: [],
      // frontCollect: 0,
      // backCollect: 0
    };
  },
  components: {
    ConfirmMove,
    ConfirmLog,
    ShowImg,
  },
  methods: {
    /** 裁剪图片 */
    async handleCutPic(e, item) {
      const res = await this.axios("/dq_admin/duibanConfirm/dikuCut", {
        params: {ids: item.id},
      })
      if (res.status !== '200') return
      this.$message.success(res.message)
      this.$emit('cutPicSuccess', item.id)
    },
    showYuanImg(e, item) {
      e.preventDefault()
      this.$refs.showImgEl.show({front: item.frontImage , back: item.backImage})
    },
    /** 同版 */
    handleShowSameVersionList(e, item) {
      e.preventDefault();
      const data = {
        coinId: item.coinKindId,
        coinItemId: item.coinKindItemId,
        coinVersionId: item.coinVersionId
      }
      this.$emit('smallVersion', data)
    },
    /** badcase收集 */
    async badcaseCollect(event,item) {
      if(this.imageType == 0 && item.collect == 1){
        event.preventDefault();
        return;
      }
      if(this.imageType == 1 && item.backCollect == 1){
        event.preventDefault();
        return;
      }
      this.frontOrBackList.push(this.imageType);
      const res = await this.axios.post("/dq_admin/duibanCoinBadcase/pushConfirmDataBadRecords", 
        {
          recordId: item.id,
          type: 10,
          frontBackList: this.frontOrBackList         
        }
      )
      // this.$loading.hide();
      if (res.status != '200') return;
      this.$message.success("收集成功");
      if(this.imageType == 0){
        item.collect = 1;
      }
      if(this.imageType == 1){
        item.backCollect = 1;
      }
      this.frontOrBackList = [];
    },

    logClick(item){
      this.$refs["log"].show(item)
    },
    editSuccess() {
      this.$emit("editSuccess");
    },
    // 推送到质检
    async pushClick(id) {
      const res = await this.axios("/dq_admin/duibanConfirm/confirmationData", {
        params: { recordId: id },
      });
      if (res.status != 200) return;
      this.$message.success(res.message || "操作成功");
      this.$emit("editSuccess");
    },
    moveClick(item) {
      this.$refs["move"].show(item);
    },
    showImage(item) {
      if (this.imageType == 0) {
        return item.cutFrontImage || item.coverList[0];
      } else {
        return item.cutBackImage || item.coverList[1];
      }
    },
    // 预览
    previewImg(index) {
      const list = this.list.map((el) => {
        let url
        if (this.imageType == 0) {
          url = el.cutFrontImage || el.coverList[0];
        } else {
          url = el.cutBackImage || el.coverList[1];
        }
        return {
          img_url: url,
        };
      });
      this.$previewImg({
        current: index,
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    // 删除
    remove(id) {
      this.axios("/dq_admin/duibanConfirm/delById", {
        params: { id: id },
      }).then((res) => {
        this.$emit("delectSuccess");
        this.$message.success("删除成功");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .content {
      margin-top: 10px;
      .image-box {
        position: relative;
        .image {
          width: 300px;
          height: 300px;
          border-radius: 4px;
        }
        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 80px;
          height: 20px;
          color: black;
          background: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: bold;
          border-radius: 4px;
        }
      }

      .name,
      .score {
        text-align: center;
        margin-top: 2px;
      }
    }
    .footer {
      margin-top: 2px;
      a {
        color: red;
        margin-right: 10px;
        &:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
      .move-btn {
        color: #1890ff;
      }
    }
  }
}
.color-red {
  color: red;
}
.null {
  margin-top: 50px;
  width: 100%;
}
</style>